<template>
  <div class="my-apps-block director-pending-checks-list">
    <div class="my-apps-left">
      <Sidebar :items="items" />
    </div>
    <div class="my-apps-right">
      <router-view />
    </div>
  </div>
</template>
<script>
import Sidebar from '@/components/admin/Sidebar.vue'
export default {
  name: 'Admin',
  components: {
    Sidebar
  },
  computed: {
    items() {
      if (this.$store.getters.isAdmin) {
        return [
          {
            link: '/admin/deadlines',
            title: this.$lang.app.deadlines,
            icon: 'calendar-range',
            type: 'main'
          },
          {
            link: '/admin/quotas',
            title: this.$lang.app.quotas,
            icon: 'people',
            type: 'main',
            children: [
              {
                link: '/admin/quotas?type=SCHOOL',
                title: this.$lang.app.schools,
                icon: 'building',
                type: 'child',
              },
              {
                link: '/admin/quotas?type=PRESCHOOL',
                title: this.$lang.app.preschools,
                icon: 'building',
                type: 'child',
              },
            ]
          },
          {
            link: '/admin/faq',
            title: this.$lang.app.faq,
            icon: 'patch-question',
            type: 'main'
          },
          {
            link: '/admin/law',
            title: this.$lang.app.law,
            icon: 'book',
            type: 'main'
          },
          // {
          //   link: '/admin/users',
          //   title: this.$lang.app.users,
          //   icon: 'people',
          //   type: 'main'
          // },
          {
            link: '/admin/reports',
            title: this.$lang.app.reports,
            icon: 'file-excel',
            type: 'main'
          },
          {
            link: '/admin/help',
            title: this.$lang.app.help,
            icon: 'info-square',
            type: 'main'
          }
        ]
      }

      if (this.$store.getters.isRaiono || this.$store.getters.isGorono) {
        return [
          {
            link: '/admin/quotas',
            title: this.$lang.app.quotas,
            icon: 'people',
            type: 'main',
            children: [
              {
                link: '/admin/quotas?type=SCHOOL',
                title: this.$lang.app.schools,
                icon: 'building',
                type: 'child',
              },
              {
                link: '/admin/quotas?type=PRESCHOOL',
                title: this.$lang.app.preschools,
                icon: 'building',
                type: 'child',
              },
            ]
          },
          {
            link: '/admin/applicants',
            title: this.$lang.app.users,
            icon: 'people',
            type: 'main'
          },
          {
            link: '/admin/reports',
            title: this.$lang.app.reports,
            icon: 'file-excel',
            type: 'main'
          },
        ]
      }

      if (this.$store.getters.isSchool) {
        return [
          {
            link: '/admin/applications',
            title: this.$lang.app.applications,
            icon: 'people',
            type: 'main',
            children: [
              {
                link: '/admin/applications?status=WAITING',
                title: this.$lang.app.waiting,
                icon: 'hourglass',
                type: 'child',
              },
              {
                link: '/admin/applications?status=ENROLLED',
                title: this.$lang.app.enrolled,
                icon: 'check-all',
                type: 'child',
              },
              {
                link: '/admin/applications?status=ENROLLED?emisSend=true',
                title: this.$lang.app.sent_emis,
                icon: 'archive',
                type: 'child',
              },
              {
                link: '/admin/applications?status=REJECTED',
                title: this.$lang.app.rejected,
                icon: 'x-circle',
                type: 'child',
              },
              // {
              //   link: '/admin/applications?status=ARCHIVED',
              //   title: this.$lang.app.archiveds,
              //   icon: 'archive',
              //   type: 'child',
              // }
            ]
          },
          {
            link: '/admin/help',
            title: this.$lang.app.help,
            icon: 'info-square',
            type: 'main'
          },
          // {
          //   link: `https://mektep.edu.gov.kg/spravka/api/v1/attestat?token=${this.$store.getters.getToken}`,
          //   title: this.$lang.app.certificate,
          //   icon: 'file-earmark-spreadsheet',
          //   type: 'main'
          // }
        ]
      }

      if (this.$store.getters.isPreschool) {
        return [
          {
            link: '/admin/groups',
            title: this.$lang.app.groups,
            icon: 'collection',
            type: 'main'
          },
          {
            link: '/admin/applications',
            title: this.$lang.app.applications,
            icon: 'people',
            type: 'main',
            children: [
              {
                link: '/admin/applications?status=WAITING&unique=true',
                title: this.$lang.app.waiting,
                icon: 'hourglass',
                type: 'child',
              },
              {
                link: '/admin/applications?status=WAITING_ENROLLING',
                title: this.$lang.app.waiting_enrolling,
                icon: 'hourglass',
                type: 'child',
              },
              {
                link: '/admin/applications?status=ENROLLED',
                title: this.$lang.app.enrolled,
                icon: 'check-all',
                type: 'child',
              },
              // {
              //   link: '/admin/applications?status=ARCHIVED',
              //   title: this.$lang.app.archiveds,
              //   icon: 'archive',
              //   type: 'child',
              // },
              {
                link: '/admin/applications?status=REJECTED',
                title: this.$lang.app.rejected,
                icon: 'x-circle',
                type: 'child',
              },
              {
                link: '/admin/applications?status=DROPPED',
                title: this.$lang.app.dropped,
                icon: 'archive',
                type: 'child',
              },
            ]
          },
          {
            link: '/admin/help',
            title: this.$lang.app.help,
            icon: 'info-square',
            type: 'main'
          }
        ]
      }

      if (this.$store.getters.isParent) {
        return [
          {
            link: '/admin/statuses',
            title: this.$lang.app.status,
            icon: 'check-lg',
            type: 'main',
          },
          {
            link: '/admin/applications',
            title: this.$lang.app.applications,
            icon: 'file',
            type: 'main',
          },
          {
            link: '/statistics',
            title: this.$lang.app.statistics,
            icon: 'bar-chart',
            type: 'main',
          }
        ]
      }

      return []
    }
  },
}
</script>
<style>
.my-apps-block {
  display: flex;
}

.director-pending-checks-list .my-apps-left {
  width: 20%;
  background: #fff;
  border-top: 1px solid #d0d0d0;
  padding: 20px 0;
  min-height: 85vh;
}

.my-apps-left {
  border-right: 1px solid #d0d0d0;
}

.director-pending-checks-list .my-apps-right {
  width: 80%;
}

.my-apps-right {
  background: #f0f0f0;
  width: calc(83.3333% - 30px);
  padding: 30px;
}

@media screen and (max-width: 1600px) {
  .my-apps-left {
    width: calc(25% + 30px);
  }

  .director-pending-checks-list .my-apps-right {
    padding: 35px;
  }

  .my-apps-right {
    padding: 30px 15px 30px 30px;
  }

  .my-apps-right {
    width: calc(75% - 30px);
  }
}

@media screen and (max-width: 1200px) {
  .director-pending-checks-list .my-apps-left {
    width: 33.3333%;
    min-height: 95vh;
  }

  .my-apps-left {
    width: calc(33.3333% + 15px);
  }

  .director-pending-checks-list .my-apps-right {
    width: 66.6666%;
    padding: 0;
  }

  .my-apps-right {
    width: calc(76.6666% - 15px);
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .my-apps-block {
    flex-wrap: wrap;
  }

  .director-pending-checks-list .my-apps-left {
    padding: 10px 0 0;
  }

  .director-pending-checks-list .my-apps-left {
    width: 100%;
    min-height: auto;
  }

  .my-apps-left {
    min-height: auto;
    padding: 15px;
  }

  .my-apps-left {
    width: 100%;
  }

  .director-pending-checks-list .my-apps-right {
    width: 100%;
  }

  .my-apps-right {
    width: 100%;
  }
}
</style>