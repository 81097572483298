<template>
  <div class="dir-links" :class="{ 'main-link': item.type === 'main' }">
    <router-link v-if="!item.children" :to="item.link" :class="{ 'active-link': $route.fullPath.endsWith(item.link) }"
      :is="item.link.startsWith('http') ? 'a' : 'router-link'" :target="item.link.startsWith('http') ? '_blank' : '_self'"
      :href="item.link">
      <i :class="`bi-${item.icon}`"></i><b>{{ item.title }}</b>
    </router-link>
    <div v-else class="dir-item" @click="collapse = !collapse">
      <i :class="`bi-${item.icon}`"></i><b>{{ item.title }}</b>
    </div>
    <template v-if="collapse">
      <SidebarItem v-for="child in item.children" :key="child.title" :item="child" />
    </template>
  </div>
</template>
<script>
import SidebarItem from '@/components/admin/SidebarItem.vue'
export default {
  name: 'SidebarItem',
  props: ['item'],
  components: {
    SidebarItem
  },
  data() {
    return {
      collapse: false,
    }
  },
  mounted() {
    if (this.$route.fullPath.includes(this.item.link)) {
      this.collapse = true
    }
  }
}
</script>
<style>
.dir-links:last-child {
  border-bottom: none;
}

.dir-links {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0 5px 20px;
  width: 100%;
}

.dir-links .active-link {
  color: #4c9ac7;
}

.dir-links.main-link {
  border-bottom: 1px solid #d0d0d0;
}

.dir-links a,
.dir-links .dir-item {
  margin: 0 5px;
  width: 100%;
  display: flex;
  color: #626262;
  text-decoration: none;
  cursor: pointer;
  padding: 5px 0;
}

.dir-links a i,
.dir-links .dir-item i {
  display: inline-block;
  margin-right: 15px;
}

.dir-links a b,
.dir-links .dir-item b {
  font-weight: 400;
  display: inline-block;
}

@media screen and (max-width: 1600px) {

  .dir-links a,
  .dir-links .dir-item {
    padding: 5px 20px;
  }
}

@media screen and (max-width: 1200px) {

  .dir-links a,
  .dir-links .dir-item {
    padding: 5px 10px 5px 15px;
  }
}
</style>