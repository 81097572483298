<template>
  <div>
    <SidebarItem v-for="item in items" :key="item.title" :item="item"/>
  </div>
</template>
<script>
import SidebarItem from '@/components/admin/SidebarItem.vue'
export default {
  name: 'Sidebar',
  components: {
    SidebarItem
  },
  props: {
    items: Array
  }
}
</script>